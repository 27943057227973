<template>
  <el-dialog
    :title="title"
    v-model="visibleDialog"
    width="800px"
    top="10vh"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    draggable
    @closed="onClosed"
    :close-on-press-escape="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="类型" prop="type_id">
        <el-select v-model="form.type_id">
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关联房间" prop="storeIds">
        <el-cascader
          :options="huxing_list"
          v-model="form.storeIds"
          style="width: 300px"
          filterable
          clearable
        ></el-cascader>
      </el-form-item>
      <el-form-item label="内容描述" prop="content">
        <el-col :span="20">
          <el-input
            v-model="form.content"
            placeholder
            :rows="10"
            type="textarea"
          ></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="状态" prop="status">
        <el-radio-group v-model="form.status">
          <el-radio
            v-for="item in enums.note_status"
            :key="item.value"
            :label="item.value"
          >
            {{ item.text }}
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="可见对象" prop="visible">
        <el-radio-group v-model="form.visible">
          <el-radio
            v-for="item in enums.note_visible"
            :key="item.value"
            :label="item.value"
          >
            {{ item.text }}
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="可修改对象" prop="editable">
        <el-radio-group v-model="form.editable">
          <el-radio
            v-for="item in enums.note_editable"
            :key="item.value"
            :label="item.value"
          >
            {{ item.text }}
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="图片">
        <upload-gallery
          :pictures="form.pic_urls"
          @change="uploadChange"
        ></upload-gallery>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="visibleDialog = false" :disabled="saving"
        >取消</el-button
      >
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确定</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import note_api from "@/http/note_api";
import huxing_api from "@/http/huxing_api";
import common_api from "@/http/common_api";
import UploadGallery from "@/views/psd/upload_gallery.vue";

export default {
  components: {
    UploadGallery,
  },
  data() {
    return {
      visibleDialog: false,
      saving: false,
      title: "",
      //项目类型列表
      typeList: [],
      huxing_list: [],
      enums: {},
      form: {},
      rules: {
        type_id: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        visible: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        editable: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: {
    item: Object,
  },
  emits: ["closed", "success"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.visibleDialog = true;
          this.form = {
            id: this.item.id,
            type_id: this.item.type_id,
            content: this.item.content,
            pic_urls: this.item.pic_urls,
            status: this.item.status,
            visible: this.item.visible,
            editable: this.item.editable,
          };
          if (this.item.id && this.item.store) {
            this.form.storeIds = [this.item.store.group_id, this.item.store.id];
          }
          this.visibleDialog = true;
        }
      },
      immediate: true,
    },
  },
  created() {
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });
    note_api.get_usable_types().then((res) => {
      if (res.code == 0) {
        this.typeList = res.data;
      }
    });
    huxing_api.get_cascader().then((res) => {
      if (res.code == 0) {
        this.huxing_list = [];
        res.data.forEach((x) => {
          var model = {
            value: x.id,
            label: x.name,
            children: [],
          };
          x.store_list.forEach((item) => {
            model.children.push({
              value: item.id,
              label: item.house_no,
            });
          });
          this.huxing_list.push(model);
        });
      }
    });
  },
  methods: {
    /**
     *
     */
    onClosed() {
      this.$emit("closed");
    },
    /**
     * 图片上传回调
     * @param {*} urls
     */
    uploadChange(urls) {
      this.form.pic_urls = urls;
    },

    /**
     * 保存
     */
    onSubmit() {
      if (this.form.storeIds && this.form.storeIds.length > 0) {
        this.form.store_id = this.form.storeIds[this.form.storeIds.length - 1];
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/seller/v1/note/edit", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.visibleDialog = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$emit("success");
              }
            })
            .finally(() => {
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped></style>
